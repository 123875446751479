import React, { useState } from 'react'
import { supabase } from '../supabaseClient'
import { ethers } from 'ethers'
import './Register.css'

export default function Register() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [registered, setRegistered] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const generateEthereumKeyPair = () => {
    const wallet = ethers.Wallet.createRandom()
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
    }
  }

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(null)

    if (password !== confirmPassword) {
      setError("Passwords do not match.")
      return
    }

    try {
      setLoading(true)

      const ethereumKeyPair = generateEthereumKeyPair()
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            ethereum_address: ethereumKeyPair.address,
            ethereum_private_key: ethereumKeyPair.privateKey,
          }
        }
      })

      if (error) throw error
      
      setRegistered(true)
    } catch (error: any) {
      setError(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  if (registered) {
    return (
      <div className="success-message">
        <h2>Registration Successful!</h2>
        <p>Your account has been registered and might be selected for the beta. We'll notify you by email if you're selected.</p>
        <a href="https://trophe.net/">Go to the main page</a>
      </div>
    )
  }

  return (
    <div className="register-container">
      <h1 className="register-header">Register for Beta</h1>
      {error && <div className="error-message">{error}</div>}
      <form className="register-form" onSubmit={handleRegister}>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            className="register-input"
            type="email"
            placeholder="Your email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            className="register-input"
            type="password"
            placeholder="Your password"
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            className="register-input"
            type="password"
            placeholder="Confirm your password"
            value={confirmPassword}
            required={true}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button className="register-button" disabled={loading}>
          {loading ? <span>Loading...</span> : <span>Register</span>}
        </button>
      </form>
      <a href="https://trophe.net/">Go to the main page</a>
    </div>
  )
}